/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.gradient-container {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}



.starry-sky {
  width: 100%;
  padding: 15px 0 15px 0;
  background: rgb(3, 14, 94);
  background: linear-gradient(288deg, rgba(3, 14, 94, 1) 0%, rgba(29, 156, 201, 1) 55%, rgba(0, 212, 255, 1) 100%);
  position: relative;
  overflow: hidden;
}

/* Create stars with pseudo-elements */
.starry-sky:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: transparent;
  z-index: 1;
  background-image: radial-gradient(2px 2px at 20px 20px, white 50%, transparent 50%),
    /* star 1 */
    radial-gradient(2px 2px at 50px 40px, white 50%, transparent 70%),
    /* star 2 */
    radial-gradient(2px 2px at 80px 50px, white 50%, transparent 40%),
    /* star 3 */
    radial-gradient(2px 2px at 100px 20px, white 50%, transparent 60%),
    /* star 4 */
    radial-gradient(3px 2px at 120px 60px, white 50%, transparent 30%),
    /* star 5 */
    radial-gradient(2px 2px at 140px 50px, white 50%, transparent 80%),
    /* star 6 */
    radial-gradient(2px 2px at 180px 30px, white 50%, transparent 50%),
    /* star 7 */
    radial-gradient(2px 2px at 200px 50px, white 50%, transparent 90%),
    /* star 8 */
    radial-gradient(2px 2px at 220px 43px, white 50%, transparent 50%),
    /* star 9 */
    radial-gradient(2px 2px at 250px 25px, white 50%, transparent 30%),
    /* star 10 */
    radial-gradient(2px 2px at 280px 60px, white 50%, transparent 40%),
    /* star 11 */
    radial-gradient(2px 2px at 300px 15px, white 50%, transparent 30%),
    /* star 12 */
    radial-gradient(2px 2px at 320px 35px, white 50%, transparent 30%),
    /* star 13 */
    radial-gradient(2px 2px at 350px 40px, white 50%, transparent 50%),
    /* star 14 */
    radial-gradient(2px 2px at 380px 50px, white 50%, transparent 60%),
    /* star 15 */
    radial-gradient(2px 2px at 400px 60px, white 50%, transparent 80%),
    /* star 16 */
    radial-gradient(2px 2px at 430px 30px, white 50%, transparent 40%),
    /* star 17 */
    radial-gradient(2px 2px at 460px 10px, white 50%, transparent 30%),
    /* star 18 */
    radial-gradient(2px 2px at 490px 25px, white 50%, transparent 50%),
    /* star 19 */
    radial-gradient(2px 2px at 510px 40px, white 50%, transparent 50%),
    /* star 20 */
    radial-gradient(2px 2px at 540px 55px, white 50%, transparent 30%),
    /* star 21 */
    radial-gradient(2px 2px at 570px 20px, white 50%, transparent 60%),
    /* star 22 */
    radial-gradient(2px 2px at 600px 30px, white 50%, transparent 50%),
    /* star 23 */
    radial-gradient(2px 2px at 630px 45px, white 50%, transparent 30%),
    /* star 24 */
    radial-gradient(2px 2px at 660px 10px, white 50%, transparent 30%),
    /* star 25 */
    radial-gradient(2px 2px at 700px 50px, white 50%, transparent 40%),
    /* star 26 */
    radial-gradient(2px 2px at 730px 35px, white 50%, transparent 20%),
    /* star 27 */
    radial-gradient(2px 2px at 760px 60px, white 50%, transparent 50%),
    /* star 28 */
    radial-gradient(2px 2px at 794px 30px, white 50%, transparent 60%),
    /* star 29 */
    radial-gradient(2px 2px at 828px 50px, white 50%, transparent 50%),
    /* star 30 */
    radial-gradient(2px 2px at 854px 15px, white 50%, transparent 30%),
    /* star 31 */
    radial-gradient(2px 2px at 886px 40px, white 50%, transparent 30%),
    /* star 32 */
    radial-gradient(2px 2px at 910px 60px, white 50%, transparent 50%),
    /* star 33 */
    radial-gradient(2px 2px at 943px 20px, white 50%, transparent 40%),
    /* star 34 */
    radial-gradient(2px 2px at 978px 50px, white 50%, transparent 40%),
    /* star 35 */
    radial-gradient(2px 2px at 1009px 30px, white 50%, transparent 50%),
    /* star 36 */
    radial-gradient(2px 2px at 1035px 60px, white 50%, transparent 30%),
    /* star 37 */
    radial-gradient(2px 2px at 1060px 40px, white 50%, transparent 70%),
    /* star 38 */
    radial-gradient(2px 2px at 1090px 20px, white 50%, transparent 80%),
    /* star 39 */
    radial-gradient(2px 2px at 1120px 30px, white 50%, transparent 30%),
    /* star 40 */
    radial-gradient(2px 2px at 1150px 10px, white 50%, transparent 60%),
    /* star 41 */
    radial-gradient(2px 2px at 1180px 50px, white 50%, transparent 50%),
    /* star 42 */
    radial-gradient(2px 2px at 1210px 60px, white 50%, transparent 30%),
    /* star 43 */
    radial-gradient(2px 2px at 1240px 15px, white 50%, transparent 20%),
    /* star 44 */
    radial-gradient(2px 2px at 1270px 40px, white 50%, transparent 50%),
    /* star 45 */
    radial-gradient(2px 2px at 1300px 55px, white 50%, transparent 20%),
    /* star 46 */
    radial-gradient(2px 2px at 1330px 25px, white 50%, transparent 40%),
    /* star 47 */
    radial-gradient(2px 2px at 1360px 60px, white 50%, transparent 20%);
  /* star 48 */

  background-size: cover;
}

.starry-sky h1 {
  color: white;
  text-align: center;
  z-index: 2;
  position: relative;
  margin: 0;
  font-family: "Chewy", system-ui;
  font-weight: 100;
  letter-spacing: 2px;

}


.app {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

.dark-mode {
  background-color: #2c2c2c;
  color: #fff;
}

.container {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.dark-mode .container {
  background-color: #3c3c3c;
}

h1 {
  margin-bottom: 10px;
  color: #007bff;
}

h2 {
  margin: 20px 0;
  font-size: 1.2rem;
}

.input-container {
  margin: 10px 0;
}

.input-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 700;
  background: #69bed5c7;
  padding-top: 15px;
  padding-bottom: 15px;
}


.dark-mode input {
  background-color: #555;
  color: #fff;
  border: 1px solid #888;
}

.generate-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-btn:hover {
  background-color: #0056b3;
}


.error-text {
  color: red;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}

.day-night-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

input:checked+.slider {
  background-color: #007bff;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.rc-dialog-wrap {
  background: #0000006b;
}

.dialog-dark-mode .rc-dialog-content {
  background-color: #636262 !important;
}

.dialog-dark-mode .rc-dialog-header {
  background-color: #636262 !important;
  border-bottom: 1px solid #8f8f8f;
}

.dialog-dark-mode .rc-dialog-title {

  color: white !important;

}

.rc-dialog-title {

  font-size: 18px !important;

}

.dialog-dark-mode .rc-dialog-close-x {
  color: white !important;
  opacity: 0.7 !important;
}

.dialog-dark-mode .rc-dialog-close {

  opacity: 0.7;
}

.dialog-server-info-container {
  border-radius: 20px;
  background-color: #30afd8;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  color: white;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.dialog-dark-mode .dialog-server-info-container {
  color: black;
}

.dialog-dark-mode .dialog-server-info-container hr {
  color: black;
  border-color: #8d8a8a;
}

.dialog-server-info-container .dialog-server-info-container-span {
  padding: 10px;
}
.dialog-server-info-more-details{
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}
.dialog-server-info-more-details-container{
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.dialog-server-info-more-details-container-heading{
  color: #30afd8;
  font-weight: 600;
}
.dialog-server-info-more-details-container-content{
  padding-top: 4px;
  font-weight: 600;
}
.dialog-dark-mode .dialog-server-info-more-details-container-content{
  color: white;
}
.dialog-server-info-more-details-back-btn{
  text-align: right;
  margin-top: 15px;
  color: #30afd8;
  cursor: pointer;
}
.stretch-container{
  width: 250px;
  margin: auto;
}
.copy-and-share-button{
  padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    margin-top: 10px;
    color: #007bff;
    background: white;
    border: 2px solid #007bff;
    border-radius: 20px;
    font-weight: 600;
   cursor: pointer;
   font-weight: bolder;
}
.drawer-share-close-btn{
  text-align: right;
  margin-top: 15px;
  color: #30afd8;
  cursor: pointer;
  margin-right: 20px;
  padding: "0 15px 0 15px";
}
.copy-and-share-button:hover{

    color: #0469d4;

    border: 2px solid #0469d4;
   
}
.copy-and-share-button-left{
  margin-right: 10px;
}
.copy-and-share-container{
  display: flex;
}
.share-icons-title{
  padding-right: 15px;
  padding-left: 15px;
}
.share-icons-container{
  
  display: flex;
}
.share-icons{
  padding-left: 15px;
}
.share-drawer{
  height: 140px!important;
   
}
.dark-share-drawer{

  background-color: #3c3c3c !important;
}
.dark-mode .copy-and-share-button{

    background: #3c3c3c!important;
   
}